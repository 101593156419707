import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { AuthManagerService } from '../../auth/auth-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly _authService: AuthService,
    private readonly _authManagerService: AuthManagerService,
    private readonly _router: Router,
  ) { }

  // only allow if we have a valid session
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(observer => {
      this._authService
        .isLoggedInSessionless()
        .pipe(first())
        .subscribe((isLoggedIn: boolean) => {
          if (isLoggedIn === false) {
            if (typeof state?.url === 'string') {
              this._authManagerService.redirectUrl = state.url.split('?')[0];
            }

            observer.next(this._router.createUrlTree(['/Access']));
          }

          observer.next(isLoggedIn);
        });
    });
  }

  // only allow if we have a valid session
  isAllowed(): Observable<boolean> {
    return new Observable(observer => {
      this._authService
        .isLoggedInSessionless()
        .pipe(first())
        .subscribe((isLoggedIn: boolean) => {
          if (isLoggedIn === false) {
            observer.next(false);
          } else {
            observer.next(true);
          }
        });
    });
  }
}
